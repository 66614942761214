<template>
  <div class="main">
    <!-- <side-menu @OpenView='OpenView'></side-menu> -->
    <side-menu-new @OpenView='OpenView' @widthClose='widthClose'></side-menu-new>
    <div class="showMain" :style="{'max-width':maxwidth}">
      <component @OpenView='OpenView' :is="showComponent"></component>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import SideMenuNew from './SideMenuNew.vue'
export default {
  components: { SideMenuNew, ...Component.components },
  name: 'ShowMain',
  data: function () {
    var showComponent = this.$route.params.viewName
    return {
      maxwidth: 'calc(100vw - 240px)',
      showComponent: showComponent
    }
  },
  methods: {
    OpenView: function (data) {
      setTimeout(() => {
        this.showComponent = data.openView
      })
    },
    widthClose (data) {
      if (data) {
        this.maxwidth = 'calc(100vw - 104px)'
      } else {
        setTimeout(() => {
          this.maxwidth = 'calc(100vw - 240px)'
        }, 400)
      }
    }
  },
  watch: {
    $route: {
      handler () {
        var showComponent = this.$route.params.viewName
        this.showComponent = showComponent
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main{
  display: flex;
  height: calc(100vh - 64px);
  .showMain{
    margin: 20px;
    /* width: calc(100vw - 240px); */
    width: 100%;
    z-index: 1;
    border-radius: 4px;
    position: relative;
    overflow: auto;
    background: #fff;
    margin-bottom: 0;
  }
}
</style>
