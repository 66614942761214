<template>
  <template v-for="(item,key) in treedata" :key="key">
    <el-submenu v-if="item.children.length>0" :index="item.id">
        <template #title>
          <i v-if="item.icon" class="iconfont" :class="item.icon"></i>
          <span>{{item.label}}</span>
        </template>
        <tree-menu :treedata="item.children"></tree-menu>
    </el-submenu>
    <el-menu-item v-if="item.children.length<=0" :index="item.id">
        <i v-if="item.icon" class="iconfont" :class="item.icon"></i>
        <template #title>{{item.label}}</template>
    </el-menu-item>
  </template>
</template>

<script>
export default {
  name: 'TreeMenu',
  props: ['treedata']
}
</script>

<style scoped>
.iconfont{
  margin-right: 5px;
}
</style>
