<template>
  <div class="nav">
    <div class="header">
      {{clicklabel}}
    </div>
    <el-menu :default-active="defaultSelectId" :collapse="isclose" class="el-menu-vertical-demo" @select="open">
      <tree-menu :treedata="treeData"></tree-menu>
    </el-menu>
    <div class="btmblock">
    </div>
    <div class="bottom">
      <el-button class="setbutton" type="text" :icon="buttonIcon" @click="setClose"></el-button>
    </div>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import TreeMenu from './TreeMenu.vue'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: { TreeMenu },
  name: 'SideMenuNew',
  data: function () {
    return {
      clicklabel: '',
      treeData: [],
      defaultSelectId: null,
      isclose: false,
      buttonIcon: 'el-icon-s-fold',
      minWidth: '200px',
      canClick: true
    }
  },
  watch: {
    $route: {
      handler () {
        this.setMenuAction()
      }
    },
    '$route.params.viewName': function () {
      FlowCtlData.flowid = ''
      FlowCtlData.docid = ''
      FlowCtlData.flowAppDetail = []
      FlowCtlData.flowMapData = {}
      FlowCtlData.flowInfo = {}
      FlowCtlData.loginID = ''
      FlowCtlData.initialized = 0
      FlowCtlData.tmpNextMsgInfo = {}
      FlowCtlData.nodeThisData = []
      FlowCtlData.formdata = {}
    },
    '$store.state.MenuData': function () {
      this.setMenuAction()
    }
  },
  created: function () {
    this.setMenuAction()
  },
  methods: {
    setClose () {
      if (!this.canClick) {
        return false
      }
      this.isclose = !this.isclose
      if (this.isclose) {
        this.buttonIcon = 'el-icon-s-unfold'
      } else {
        this.buttonIcon = 'el-icon-s-fold'
      }
      this.$emit('widthClose', this.isclose)
      this.canClick = false
      setTimeout(() => {
        this.canClick = true
      }, 500)
    },
    setMenuAction () {
      for (let s = 0; s < this.$store.state.MenuData.length; s++) {
        // eslint-disable-next-line eqeqeq
        if (this.$store.state.MenuData[s].alias == this.$route.params.frameName) {
          this.treeData = []
          this.$nextTick(function () {
            this.treeData = this.$store.state.MenuData[s].children
            if (this.$route.params.viewName) {
              for (var i in this.treeData) {
                if (this.treeData[i].openWay === 4) {
                  if (this.treeData[i].alias === this.$route.query.ViewId) {
                    this.defaultSelectId = this.treeData[i].id
                    break
                  }
                } else {
                  if (this.treeData[i].alias === this.$route.params.viewName) {
                    this.defaultSelectId = this.treeData[i].id
                    break
                  }
                }
                if (this.treeData[i].children.length > 0) {
                  for (var v in this.treeData[i].children) {
                    if (this.treeData[i].children[v].openWay === 4) {
                      if (this.treeData[i].children[v].alias === this.$route.query.ViewId) {
                        this.defaultSelectId = this.treeData[i].children[v].id
                        break
                      }
                    } else {
                      if (this.treeData[i].children[v].alias === this.$route.params.viewName) {
                        this.defaultSelectId = this.treeData[i].children[v].id
                        break
                      }
                    }
                  }
                }
              }
            } else {
              this.defaultSelectId = this.$store.state.MenuData[s].defaultSelectId
            }
            var nodeData = this.getMenuDataById(this.treeData, this.defaultSelectId)
            if (nodeData.openWay === 3) {
              const src = {}
              src.openView = 'IframeView'
              setTimeout(() => { this.$emit('OpenView', src) }, 1)
            }
            if (nodeData != null) {
              request('/api/system/menu/getButtonByMenuId/' + this.defaultSelectId, 'post', {}).then((response) => {
                nodeData.menuData = response.data
                this.$store.commit('setViewMenuData', nodeData)
              })
            }
          })
        }
      }
    },
    getMenuDataById: function (MenuData, Id) {
      if (MenuData && Id) {
        for (var menu of MenuData) {
          if (menu.id === Id) {
            return menu
          } else if (menu.children.length > 0) {
            const tempData = this.getMenuDataById(menu.children, Id)
            if (JSON.stringify(tempData) !== '{}') {
              return tempData
            }
          }
        }
        return {}
      } else {
        return {}
      }
    },
    open: function (index, path) {
      var menudta = JSON.parse(JSON.stringify(this.treeData))
      for (var pathNum in path) {
        for (var menu of menudta) {
          if (menu.id === path[pathNum] && path.length - 1 > pathNum) {
            menudta = menu.children
          } else if (menu.id === path[pathNum]) {
            menudta = menu
          }
        }
      }
      if (window.event.ctrlKey) {
        let uri = '/'
        if (menudta.openWay === 1) {
          const routeList = this.$route.path.split('/')
          routeList[routeList.length - 1] = menudta.alias
          uri = routeList.join('/')
        }
        if (menudta.openWay === 3) {
          const routeList = this.$route.path.split('/')
          routeList[routeList.length - 1] = menudta.alias
          uri = routeList.join('/')
        }
        if (menudta.openWay === 4) {
          const routeList = this.$route.path.split('/')
          routeList[routeList.length - 1] = menudta.openView + '?ViewId=' + menudta.alias
          uri = routeList.join('/')
        }
        window.open(uri)
        return false
      }
      if (menudta.openWay === 1) {
        request('/api/system/menu/getButtonByMenuId/' + menudta.id, 'post', {}).then((response) => {
          menudta.menuData = response.data
          this.$store.commit('setViewMenuData', menudta)
        })
        this.$router.push({ name: 'frame', params: { viewName: menudta.alias } })
        this.$emit('OpenView', menudta)
      }
      if (menudta.openWay === 4) {
        request('/api/system/menu/getButtonByMenuId/' + menudta.id, 'post', {}).then((response) => {
          menudta.menuData = response.data
          this.$store.commit('setViewMenuData', menudta)
        })
        this.$router.push({ name: 'frame', params: { viewName: menudta.openView }, query: { ViewId: menudta.alias } })
        this.$emit('OpenView', menudta)
      }
      if (menudta.openWay === 2) {
        window.open(menudta.menuUrl)
      }
      if (menudta.openWay === 3) {
        var asyncLoad = new Promise((resolve, reject) => {
          this.$router.push({ name: 'frame', params: { viewName: menudta.alias } })
          resolve()
        })
        asyncLoad.then(() => {
          this.$store.commit('setViewMenuData', menudta)
          const src = {}
          src.openView = 'IframeView'
          setTimeout(() => { this.$emit('OpenView', src) }, 1)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .nav {
    flex-grow: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    .el-menu-vertical-demo:not(.el-menu--collapse):not(.horizontal-collapse-transition){
      min-width: 200px;
    }
    .btmblock{
      height: 40px;
      background: #fff;
    }
    .bottom{
      position: fixed;
      bottom: 0;
      height: 40px;
      background: #fff;
      .setbutton{
        font-size: 25px;
        margin-bottom: 32px;
        padding: 0;
        margin-left: 5px;
      }
    }
  }

  .nav .header {
    height: 40px;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    padding-left: 20px;
    display: none;
  }
.nav :deep(.el-menu){
  .el-submenu__title:hover,.el-menu-item:hover {
        background-color: #dee0e3;
  }
}
// 侧导航样式修改
.nav{
  :deep(.el-menu.el-menu-vertical-demo){
    border-right: none
  }
  :deep(.el-menu-vertical-demo .el-menu-item.is-active){
    color: #409eff;
    background-color: #ecf5ff;
    border-right: 3px solid #409eff;
  }
  :deep(.el-menu-vertical-demo .el-menu :not(.is-active).el-menu-item:hover){
    background-color: #dee0e3;
  }
  :deep(.el-menu .el-submenu__title:hover){
    background-color: #dee0e3 !important;
  }
  :deep(.el-menu-vertical-demo .is-active.is-opened .el-submenu__title){
    color: #409eff;
  }
  :deep(.el-menu-vertical-demo .is-active.is-opened .el-submenu__title i){
    color: #409eff;
  }
}
</style>
