import { defineAsyncComponent } from 'vue'
export default {
  components: {
    AreaSelector: defineAsyncComponent(() => import('@/components/plug/AreaSelector.vue')),
    SupplierRegistration: defineAsyncComponent(() => import('@/views/mainDataSupplier/register/SupplierRegistration.vue')),
    CompanyInfo: defineAsyncComponent(() => import('@/views/mainDataSupplier/register/SupplierRegistration.vue')),
    SideMenu: defineAsyncComponent(() => import('@/components/SideMenu.vue')),
    IframeView: defineAsyncComponent(() => import('@/components/IframeView.vue')),
    SupplierList: defineAsyncComponent(() => import('@/views/mainDataSupplier/register/SupplierList.vue')),
    IndexHeader: defineAsyncComponent(() => import('@/components/IndexHeader.vue')),
    bid: defineAsyncComponent(() => import('@/views/invitation/invitationBid/Invitation.vue')),
    AdminAccount: defineAsyncComponent(() => import('@/views/system/account/AdminAccount.vue')),
    dialogBox: defineAsyncComponent(() => import('@/components/plug/DialogBox.vue')),
    RoleBody: defineAsyncComponent(() => import('@/views/system/account/dialogBody/RoleBody.vue')),
    AdminAccountTable: defineAsyncComponent(() => import('@/views/system/account/AdminAccountTable.vue')),
    ListMenu: defineAsyncComponent(() => import('@/components/actionList/ListMenu.vue')),
    ListSearch: defineAsyncComponent(() => import('@/components/actionList/ListSearch.vue')),
    TableNav: defineAsyncComponent(() => import('@/components/actionList/TableNav.vue')),
    TableTab: defineAsyncComponent(() => import('@/components/actionList/TableTab.vue')),
    TableButtonGroup: defineAsyncComponent(() => import('@/components/actionList/TableButtonGroup.vue')),
    GlobalTable: defineAsyncComponent(() => import('@/components/actionList/GlobalTable.vue')),
    ApprovalList: defineAsyncComponent(() => import('@/views/approval/approvalList/ApprovalList.vue')),
    // MaterialApproval: defineAsyncComponent(() => import('@/views/approval/materialApproval/MaterialApproval.vue')),
    SupplierLcm: defineAsyncComponent(() => import('@/views/mainDataSupplier/lifecycle/SupplierLcm.vue')),
    SupplierLcmtable: defineAsyncComponent(() => import('@/views/mainDataSupplier/lifecycle/SupplierLcm.vue')),
    MenuConfig: defineAsyncComponent(() => import('@/views/system/menuManage/MenuConfig.vue')),
    ExpertMark: defineAsyncComponent(() => import('@/views/invitation/expermark/ExpertMark.vue')),
    ExpertRating: defineAsyncComponent(() => import('@/views/invitation/module/ExpertRating.vue')),
    ExpertRatingEnd: defineAsyncComponent(() => import('@/views/invitation/module/ExpertRatingEnd.vue')),
    setUserInfo: defineAsyncComponent(() => import('@/views/index/UserInfo.vue')),
    MaterialManage: defineAsyncComponent(() => import('@/views/mainDataSupplier/materialManage/MaterialManage.vue')),
    RolemanageMent: defineAsyncComponent(() => import('@/views/system/rolemanagement/RolemanageMent.vue')),
    InquiryAndQuotation: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndQuotation.vue')),
    InquiryAndQuotationTab: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndQuotationTab.vue')),
    InvitingSuppliers: defineAsyncComponent(() => import('@/views/invitation/module/InvitingSuppliers.vue')),
    RoleDialogBody: defineAsyncComponent(() => import('@/views/system/rolemanagement/RoleDialogBody.vue')),
    AuthDialogBody: defineAsyncComponent(() => import('@/views/system/rolemanagement/AuthDialogBody.vue')),
    BidInfo: defineAsyncComponent(() => import('@/views/invitation/module/BidInfo.vue')),
    BidResult: defineAsyncComponent(() => import('@/views/invitation/module/BidResult.vue')),
    EvaluationCriteria: defineAsyncComponent(() => import('@/views/invitation/module/EvaluationCriteria.vue')),
    BidEvaluationTeam: defineAsyncComponent(() => import('@/views/invitation/module/BidEvaluationTeam.vue')),
    quotationSheetBidder: defineAsyncComponent(() => import('@/views/invitation/module/QuotationSheetBidder.vue')),
    BidForm: defineAsyncComponent(() => import('@/views/invitation/invitationBid/BidForm.vue')),
    ChangeBid: defineAsyncComponent(() => import('@/views/invitation/invitationBid/ChangeBid.vue')),
    RepetBid: defineAsyncComponent(() => import('@/views/invitation/invitationBid/RepetBid.vue')),
    ConfirmBid: defineAsyncComponent(() => import('@/views/invitation/invitationBid/ConfirmBid.vue')),
    BidFormAll: defineAsyncComponent(() => import('@/views/invitation/invitationBid/BidFormAll.vue')),
    BidNotice: defineAsyncComponent(() => import('@/views/salesLeads/bidNotice/BidNotice.vue')),
    BidDetails: defineAsyncComponent(() => import('@/views/salesLeads/bidNotice/BidDetails.vue')),
    QuotationBody: defineAsyncComponent(() => import('@/views/salesLeads/bidNotice/QuotationBody.vue')),

    // BidNoticeRating: defineAsyncComponent(() => import('@/views/invitation/bidNotice/BidNoticeRating.vue')),
    ChoiceSupplierBody: defineAsyncComponent(() => import('@/views/invitation/module/ChoiceSupplierBody.vue')),
    MyBid: defineAsyncComponent(() => import('@/views/salesLeads/myBid/MyBid.vue')),
    MyBidRating: defineAsyncComponent(() => import('@/views/salesLeads/myBid/MyBidRating.vue')),
    BidAllInfo: defineAsyncComponent(() => import('@/views/invitation/module/BidAllInfo.vue')),
    MaterialBody: defineAsyncComponent(() => import('@/views/mainDataSupplier/materialManage/MaterialBody.vue')),
    evaluationBidForm: defineAsyncComponent(() => import('@/views/invitation/evaluationBid/EvaluationBidForm.vue')),
    Myorder: defineAsyncComponent(() => import('@/views/contractsAndOrder/myOrder/Myorder.vue')),
    OrderDetail: defineAsyncComponent(() => import('@/views/contractsAndOrder/myOrder/orderDetail/OrderDetail.vue')),
    RelateOrder: defineAsyncComponent(() => import('@/views/contractsAndOrder/relateOrder/RelateOrder.vue')),
    IssueInquiry: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndDetails/IssueInquiry.vue')),
    IssueDetails: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndDetails/IssueDetails.vue')),
    SecondInquiry: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndDetails/module/SecondInquiry.vue')),
    QuotationInformation: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndDetails/module/QuotationInformation.vue')),
    SubmitApproval: defineAsyncComponent(() => import('@/views/invitation/InquiryAndQuotation/InquiryAndDetails/module/SubmitApproval.vue')),
    AddInLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/AddInLibrary.vue')),
    AddOutLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/outLibrary/AddOutLibrary.vue')),
    InventoryManagement: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/purchasingOperation/InventoryManagement.vue')),
    InLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/InLibrary.vue')),
    OutLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/outLibrary/OutLibrary.vue')),
    AddInLibraryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/module/AddInLibraryBody.vue')),
    AddInDeliveryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/module/AddInDeliveryBody.vue')),
    AddPurchaseCodrBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/module/AddPurchaseCodrBody.vue')),
    AddInLibraryTable: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/module/AddInLibraryTable.vue')),
    AddInOrderBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/inLibrary/module/AddInOrderBody.vue')),
    AddOutLibraryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchasingStock/outLibrary/module/AddOutLibraryBody.vue')),
    AddMaterielInLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielInLibrary/AddMaterielInLibrary.vue')),
    AddMaterielOutLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielOutLibary/AddMaterielOutLibrary.vue')),
    AddMaterielMoveLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielOutLibary/AddMaterielMoveLibrary.vue')),
    AddMaterielInLibraryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielInLibrary/module/AddMaterielInLibraryBody.vue')),
    AddMaterielInDeliveryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielInLibrary/module/AddMaterielInDeliveryBody.vue')),
    AddMaterielInLibraryTable: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielInLibrary/module/AddMaterielInLibraryTable.vue')),
    AddMaterielInOrderBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielInLibrary/module/AddMaterielInOrderBody.vue')),
    AddMaterielOutLibraryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielOutLibary/module/AddMaterielOutLibraryBody.vue')),
    AddMaterielMoveLibraryBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielOutLibary/module/AddMaterielMoveLibraryBody.vue')),
    MaterielInLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielInLibrary/MaterielInLibrary.vue')),
    MaterielOutLibrary: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/materielOutLibary/MaterielOutLibrary.vue')),
    ManagingInventory: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/managingInventory/ManagingInventory.vue')),
    ContractList: defineAsyncComponent(() => import('@/views/contractsAndOrder/contractManagement/contractList/ContractList.vue')),
    SupplierContractList: defineAsyncComponent(() => import('@/views/contractsAndOrder/supplierContract/supplierContractList/SupplierContractList.vue')),
    ConfirmationContract: defineAsyncComponent(() => import('@/views/contractsAndOrder/contractManagement/confirmationContract/ConfirmationContract.vue')),
    OrderdialogBody: defineAsyncComponent(() => import('@/views/contractsAndOrder/contractManagement/confirmationContract/OrderdialogBody.vue')),
    OrderdetailDialog: defineAsyncComponent(() => import('@/views/contractsAndOrder/myOrder/orderDetail/OrderdetailDialog.vue')),
    DraftaContract: defineAsyncComponent(() => import('@/views/contractsAndOrder/contractManagement/draftaContract/DraftaContract.vue')),
    SupplierDraft: defineAsyncComponent(() => import('@/views/contractsAndOrder/supplierContract/supplierDraft/SupplierDraft.vue')),
    PurchaseDemand: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchaseDemand/PurchaseDemand.vue')),
    PurchaseSingler: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchaseSingle/PurchaseSingler.vue')),
    SupplierDemand: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierDemand/SupplierDemand.vue')),
    SupplierSingler: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierSingle/SupplierSingler.vue')),
    Supplementdialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/lifecycle/lifecycleDialogbody/Supplementdialog.vue')),
    LifecycleDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/lifecycle/lifecycleDialogbody/LifecycleDialog.vue')),
    AddPurchaseDemand: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchaseDemand/AddPurchaseDemand.vue')),
    AddPurchaseNumBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchaseDemand/module/AddPurchaseNumBody.vue')),
    AddSupplierDemand: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierDemand/AddSupplierDemand.vue')),
    HairSupplierDemand: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/hairSupplierDemand/HairSupplierDemand.vue')),
    HairSupplierDetails: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/hairSupplierDemand/HairSupplierDetails.vue')),
    HairPurchaserDemand: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/hairPurchaseDemand/HairPurchaserDemand.vue')),
    HairPurchaserDetails: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/hairPurchaseDemand/HairPurchaserDetails.vue')),
    PurchasePlan: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchasePlan/PurchasePlan.vue')),
    PurchasePlanTable: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchasePlan/PurchasePlanTable.vue')),
    PurchaseBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchasePlan/model/PurchaseBody.vue')),
    SupplierPlan: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierPlan/SupplierPlan.vue')),
    SupplierPlanTable: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierPlan/SupplierPlanTable.vue')),
    SupplierBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierPlan/model/SupplierBody.vue')),
    ApplyBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierDemand/module/ApplyBody.vue')),
    RevokeBody: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierDemand/module/RevokeBody.vue')),
    StocksInventory: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierStock/stockInventory/StocksInventory.vue')),
    DataAuthConfig: defineAsyncComponent(() => import('@/views/system/dataAuth/DataAuthConfig.vue')),
    ContractSign: defineAsyncComponent(() => import('@/views/contractsAndOrder/supplierContract/contractSign/ContractSign.vue')),
    BarcodeManagement: defineAsyncComponent(() => import('@/views/contractsAndOrder/barcodeManagement/purbarcodeManagement/BarcodeManagement.vue')),
    BarcodeDialogbody: defineAsyncComponent(() => import('@/views/contractsAndOrder/barcodeManagement/purbarcodeManagement/BarcodeDialogbody.vue')),
    InquiryNotice: defineAsyncComponent(() => import('@/views/salesLeads/inquiryNotice/InquiryNotice.vue')),
    Myprice: defineAsyncComponent(() => import('@/views/salesLeads/myPrice/Myprice.vue')),
    ComponentListdialog: defineAsyncComponent(() => import('@/views/contractsAndOrder/myOrder/orderDetail/ComponentListdialog.vue')),
    SeebarcodeDialogbody: defineAsyncComponent(() => import('@/views/contractsAndOrder/barcodeManagement/purbarcodeManagement/SeebarcodeDialogbody.vue')),
    Statement: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/stateMent/Statement.vue')),
    ProofofReceipt: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/proofofReceipt/ProofofReceipt.vue')),
    PurchaseInvoice: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/purchaseInvoice/PurchaseInvoice.vue')),
    SupplierStatement: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierStatement/SupplierStatement.vue')),
    Supplierinvoice: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/Supplierinvoice.vue')),
    SupbarcodeManagement: defineAsyncComponent(() => import('@/views/contractsAndOrder/barcodeManagement/supbarcodeManagement/SupbarcodeManagement.vue')),
    SupBarcodeDialogbody: defineAsyncComponent(() => import('@/views/contractsAndOrder/barcodeManagement/supbarcodeManagement/SupBarcodeDialogbody.vue')),
    SupseebarcodeDialogbody: defineAsyncComponent(() => import('@/views/contractsAndOrder/barcodeManagement/supbarcodeManagement/SupseebarcodeDialogbody.vue')),
    CreateaStatement: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/createaStatement/CreateaStatement.vue')),
    StatementDetail: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/createaStatement/StatementDetail.vue')),
    StatementInfo: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/createaStatement/StatementInfo.vue')),
    InquiryDetails: defineAsyncComponent(() => import('@/views/salesLeads/inquiryNotice/InquiryDetails.vue')),
    InquiryDetailDialog: defineAsyncComponent(() => import('@/views/salesLeads/inquiryNotice/InquiryDetailDialog.vue')),
    PayPlan: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/payplan/PayPlan.vue')),
    PaymentFind: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/PaymentFind.vue')),
    AccountsPayable: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/accountsPayable/AccountsPayable.vue')),
    PurchaseRepair: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseRepair/PurchaseRepair.vue')),
    SupplierRepair: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/supplierRepairList/SupplierRepair.vue')),
    CreatRepair: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseRepair/CreatRepair.vue')),
    CreatRepairDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseRepair/CreatRepairDialog.vue')),
    ChangeDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseRepair/ChangeDialog.vue')),
    SupplierRepairDetail: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/supplierRepairList/SupplierRepairDetail.vue')),
    RefuseBtnDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/btnDialogBody/RefuseBtnDialog.vue')),
    ConfirmBtnDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/btnDialogBody/ConfirmBtnDialog.vue')),
    ConfirmReceiptDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/btnDialogBody/ConfirmReceiptDialog.vue')),
    ShipmentsBtnDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/btnDialogBody/ShipmentsBtnDialog.vue')),
    ChoiceDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/btnDialogBody/ChoiceDialog.vue')),
    StatementDetaildialog: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/createaStatement/StatementDetaildialog.vue')),
    CompanyMessage: defineAsyncComponent(() => import('@/views/myCompany/companyInfo/CompanyRegistration.vue')),
    CompanyRegistration: defineAsyncComponent(() => import('@/views/myCompany/companyInfo/CompanyRegistration.vue')),
    materielManagement: defineAsyncComponent(() => import('@/views/myCompany/materialManagement/ComMaterialManage.vue')),
    ComMaterialBody: defineAsyncComponent(() => import('@/views/myCompany/materialManagement/ComMaterialBody.vue')),
    AddAddress: defineAsyncComponent(() => import('@/views/myCompany/myAddress/AddAddress.vue')),
    MyAddress: defineAsyncComponent(() => import('@/views/myCompany/myAddress/MyAddress.vue')),
    AddContacts: defineAsyncComponent(() => import('@/views/myCompany/myContacts/AddContacts.vue')),
    MyContacts: defineAsyncComponent(() => import('@/views/myCompany/myContacts/MyContacts.vue')),
    StatementSeeDetail: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/stateMent/StatementSeeDetail.vue')),
    StatementSeeDetailDialog: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/stateMent/StatementSeeDetailDialog.vue')),
    PurchaseInvoiceDetail: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/purchaseInvoice/PurchaseInvoiceDetail.vue')),
    PayPlanAdddialog: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/payplan/PayPlanAdddialog.vue')),
    CreateaplanPayreq: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/CreateaplanPayreq.vue')),
    CreatePaymentreq: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/CreatePaymentreq.vue')),
    CreateaplanPayreqdialog: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/CreateaplanPayreqdialog.vue')),
    PaymentFindDetail: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/PaymentFindDetail.vue')),
    AdvancePaymentFindDetail: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/AdvancePaymentFindDetail.vue')),
    SupplierStatementDetail: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierStatement/SupplierStatementDetail.vue')),
    SupStatementDetailDialogN: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierStatement/model/SupStatementDetailDialogN.vue')),
    SupStatementDetailDialogY: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierStatement/model/SupStatementDetailDialogY.vue')),
    CreateonlineInvoices: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/CreateonlineInvoices.vue')),
    CreateonlineInvoicesdialog: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/model/CreateonlineInvoicesdialog.vue')),
    SupplierinvoiceDetaildialog: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/model/SupplierinvoiceDetaildialog.vue')),
    SupplierinvoiceDetail: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/SupplierinvoiceDetail.vue')),
    OnlineInvoiceInfo: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/OnlineInvoiceInfo.vue')),
    Notice: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/notice/Notice.vue')),

    Advise: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/advise/Advise.vue')),
    AdviseManage: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/adviseManage/AdviseManage.vue')),
    accountability: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/accountability/accountability.vue')),
    AdviseList: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/adviseList/AdviseList.vue')),

    ApplyingDetails: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/notice/ApplyingDetails.vue')),
    ProductionDetails: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/notice/ProductionDetails.vue')),
    ExceptionManage: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/exceptionManage/ExceptionManage.vue')),
    AddExceptionManage: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/exceptionManage/AddExceptionManage.vue')),
    AddExceptionReport: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/exceptionManage/AddExceptionReport.vue')),
    AddExceptionFA: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/exceptionManage/AddExceptionFA.vue')),
    AddClaimForm: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/responsibility/AddClaimForm.vue')),
    Responsibility: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/responsibility/Responsibility.vue')),
    QualityList: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualityList/QualityList.vue')),
    EmployeesAccount: defineAsyncComponent(() => import('@/views/myCompany/employeesAccount/EmployeesAccount.vue')),
    EmployeesRoleBody: defineAsyncComponent(() => import('@/views/myCompany/employeesAccount/dialogBody/EmployeesRoleBody.vue')),
    materialVoucherNoDialog: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierStatement/model/materialVoucherNoDialog.vue')),
    DraftaContractdialog: defineAsyncComponent(() => import('@/views/contractsAndOrder/contractManagement/draftaContract/DraftaContractdialog.vue')),
    ComAccount: defineAsyncComponent(() => import('@/views/myCompany/companyAccount/ComAccount.vue')),
    ComAccountDialogBody: defineAsyncComponent(() => import('@/views/myCompany/companyAccount/ComAccountDialogBody.vue')),
    MaterialVoucherNodialog: defineAsyncComponent(() => import('@/views/financialManagement/purchasFinancialMg/createaStatement/MaterialVoucherNodialog.vue')),
    PurchaseFscList: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseFCS/FscList.vue')),
    PurchaseFscForm: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseFCS/FscForm.vue')),
    SupplierFscList: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierFCS/SupplierFscList.vue')),
    MVoucherNoDialog: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/model/MVoucherNoDialog.vue')),
    MaterialApproval: defineAsyncComponent(() => import('@/views/approval/materialApproval/MaterialApproval.vue')),
    RepairDetail: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseRepair/RepairDetail.vue')),
    RepairDetailDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseRepair/RepairDetailDialog.vue')),
    OnlineinvoiceDetailstable: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/OnlineinvoiceDetailstable.vue')),
    LocationManagement: defineAsyncComponent(() => import('@/views/myCompany/locationManagement/LocationManagement.vue')),
    AddLocation: defineAsyncComponent(() => import('@/views/myCompany/locationManagement/AddLocation.vue')),
    CreatePaymentreqNext: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/CreatePaymentreqNext.vue')),
    ShroffAccountNumber: defineAsyncComponent(() => import('@/views/myCompany/shroffAccountNumber/ShroffAccountNumber.vue')),
    AddShroffAccountNumber: defineAsyncComponent(() => import('@/views/myCompany/shroffAccountNumber/AddShroffAccountNumber.vue')),
    ProductionBase: defineAsyncComponent(() => import('@/views/system/base/ProductionBase.vue')),
    EditProductionBase: defineAsyncComponent(() => import('@/views/system/base/EditProductionBase.vue')),
    Manuallydialog: defineAsyncComponent(() => import('@/views/financialManagement/supplierFinancialMg/supplierInvoice/model/Manuallydialog.vue')),
    OrderInformation: defineAsyncComponent(() => import('@/views/contractsAndOrder/myOrder/orderDetail/OrderInformation.vue')),
    ChangeRecord: defineAsyncComponent(() => import('@/views/contractsAndOrder/myOrder/orderDetail/ChangeRecord.vue')),
    BarCodeApproval: defineAsyncComponent(() => import('@/views/approval/barCodeApproval/BarCodeApproval.vue')),
    PrepaidSterilisationdialog: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/paymentFind/PrepaidSterilisationdialog.vue')),
    QuotationBodyShow: defineAsyncComponent(() => import('@/views/salesLeads/myBid/QuotationBodyShow.vue')),
    SNDialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierRepair/supplierRepairList/SNDialog.vue')),
    AddpayPlandialog: defineAsyncComponent(() => import('@/views/financialManagement/invoiceandPayment/payplan/AddpayPlandialog.vue')),
    WareHouse: defineAsyncComponent(() => import('@/views/system/warehouse/WareHouse.vue')),
    Factory: defineAsyncComponent(() => import('@/views/system/factory/Factory.vue')),
    Mytodo: defineAsyncComponent(() => import('@/views/index/module/Mytodo.vue')),
    MessageNotification: defineAsyncComponent(() => import('@/views/index/module/MessageNotification.vue')),
    IndexCharts: defineAsyncComponent(() => import('@/views/index/module/IndexCharts.vue')),
    IndexChart: defineAsyncComponent(() => import('@/views/index/module/IndexChart.vue')),
    pieChart: defineAsyncComponent(() => import('@/views/index/module/pieChart.vue')),
    SystemNotice: defineAsyncComponent(() => import('@/views/index/module/SystemNotice.vue')),
    LogInfo: defineAsyncComponent(() => import('@/views/system/sysManage/LogInfo.vue')),
    ApprovalMind: defineAsyncComponent(() => import('@/components/ApprovalMind.vue')),
    ReorderDetail: defineAsyncComponent(() => import('@/views/contractsAndOrder/relateOrder/reorderDetail/ReorderDetail.vue')),
    ReOrderInformation: defineAsyncComponent(() => import('@/views/contractsAndOrder/relateOrder/reorderDetail/ReOrderInformation.vue')),
    ReChangeRecord: defineAsyncComponent(() => import('@/views/contractsAndOrder/relateOrder/reorderDetail/ReChangeRecord.vue')),
    ReComponentListdialog: defineAsyncComponent(() => import('@/views/contractsAndOrder/relateOrder/reorderDetail/ReComponentListdialog.vue')),
    ChangePassword: defineAsyncComponent(() => import('@/components/ChangePassword.vue')),
    PsnInfo: defineAsyncComponent(() => import('@/components/PsnInfo.vue')),

    EvaluationQuery: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationQuery/EvaluationQuery.vue')),
    EvaluationDetails: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationQuery/EvaluationDetails.vue')),
    OperationBody: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationQuery/module/OperationBody.vue')),
    ScoreBody: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationQuery/module/ScoreBody.vue')),

    FileManage: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileManage/FileManage.vue')),
    FileDetails: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileManage/FileDetails.vue')),
    FileOperationBody: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileManage/module/FileOperationBody.vue')),
    AddFiled: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileManage/AddFiled.vue')),

    ParameterDefinition: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/parameterDefinition/ParameterDefinition.vue')),
    SupchoiceDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/parameterDefinition/module/SupchoiceDialog.vue')),
    ProcurementCategory: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/parameterDefinition/module/ProcurementCategory.vue')),
    AddcheckDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/parameterDefinition/module/AddcheckDialog.vue')),
    EvaluationIndicators: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluationIndicators/EvaluationIndicators.vue')),
    ScoredetailTable: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluationIndicators/ScoredetailTable.vue')),
    ScoredetailAddDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluationIndicators/ScoredetailAddDialog.vue')),
    EvaluationAddDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluationIndicators/EvaluationAddDialog.vue')),
    DefinitionTable: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluationIndicators/DefinitionTable.vue')),
    DefinitionAddDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluationIndicators/DefinitionAddDialog.vue')),
    Evaluatetemplate: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/Evaluatetemplate.vue')),
    EvaluateModelDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/EvaluateModelDialog.vue')),
    EvaluateIndexDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/EvaluateIndexDialog.vue')),
    Evaluatetable: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/Evaluatetable.vue')),
    EvaluateModel: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/EvaluateModel.vue')),
    SubcriteriAallot: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/SubcriteriAallot.vue')),
    SubcriteriAddDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/SubcriteriAddDialog.vue')),
    Indicatorsdetail: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/Indicatorsdetail.vue')),
    IndicatorsAddDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/evaluatetemplate/IndicatorsAddDialog.vue')),
    FileFilling: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileFilling/FileFilling.vue')),

    fileFillingTable: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileFilling/fileFillingTable.vue')),
    FileFillingDetails: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationManage/fileFilling/FileFillingDetails.vue')),
    ReworkInput: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/reworkInput/ReworkInput.vue')),
    Warranty: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/reworkInput/reworkTable/Warranty.vue')),
    WarrantyDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/reworkInput/reworkTable/WarrantyDialog.vue')),
    KeyIssues: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/reworkInput/reworkTable/KeyIssues.vue')),
    KeyIssuesDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/reworkInput/reworkTable/KeyIssuesDialog.vue')),
    ImportData: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/reworkInput/reworkTable/ImportData.vue')),
    SupplierDefinition: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/supplierDefinition/SupplierDefinition.vue')),

    SupplierAllocation: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/supplierAllocation/SupplierAllocation.vue')),
    SubModel: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/supplierDefinition/module/SubModel.vue')),

    AddSupAllDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/supplierAllocation/module/AddSupAllDialog.vue')),
    AllocationDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/supplierAllocation/module/AllocationDialog.vue')),
    AddFileList: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/adviseList/AddFileList.vue')),
    ShowFileList: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/adviseList/ShowFileList.vue')),
    AddSupDialog: defineAsyncComponent(() => import('@/views/mainDataSupplier/evaluationSettings/supplierDefinition/module/AddSupDialog.vue')),
    SuplifecycleadjustList: defineAsyncComponent(() => import('@/views/mainDataSupplier/changeRecord/suplifecycleadjust/SuplifecycleadjustList.vue')),
    SupLifecycleApproval: defineAsyncComponent(() => import('@/views/approval/supLifecycleApproval/SupLifecycleApproval.vue')),
    passDialog: defineAsyncComponent(() => import('@/views/approval/supLifecycleApproval/passDialog.vue')),
    RejectBtnDialog: defineAsyncComponent(() => import('@/views/approval/supLifecycleApproval/RejectBtnDialog.vue')),
    AdjustmentBasesDialog: defineAsyncComponent(() => import('@/views/approval/supLifecycleApproval/AdjustmentBasesDialog.vue')),
    ChoosequalityDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/exceptionManage/ChoosequalityDialog.vue')),
    ChoosequalityDialogFA: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/responsibility/ChoosequalityDialogFA.vue')),
    ReviewProjectOps: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/projectmaintenance/ReviewProjectOps.vue')),
    AddscoreDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/projectmaintenance/AddscoreDialog.vue')),
    EditAddscoreDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/projectmaintenance/EditAddscoreDialog.vue')),
    ScoreDetail: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/projectmaintenance/ScoreDetail.vue')),
    AuditScore: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/auditScore/AuditScore.vue')),
    CreatedDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/auditScore/CreatedDialog.vue')),
    CheckScoreDetail: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/auditScore/CheckScoreDetail.vue')),
    GradingDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/auditScore/GradingDialog.vue')),
    AuditResultInquiry: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/queryauditResults/AuditResultInquiry.vue')),
    IncomingDetails: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/advise/IncomingDetails.vue')),
    AddAdviseReport: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/adviseManage/AddAdviseReport.vue')),
    BackDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/adviseManage/BackDialog.vue')),
    Complaintsopinion: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/accountability/Complaintsopinion.vue')),
    NoticeofClaim: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/accountability/NoticeofClaim.vue')),
    SupAuditResultInquiry: defineAsyncComponent(() => import('@/views/qualityCollaboration/supplier/supAuditResultInquiry/SupAuditResultInquiry.vue')),
    ExceptionManageDetail: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/exceptionManage/ExceptionManageDetail.vue')),
    logdialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/purchasePlan/model/logdialog.vue')),
    Suplogdialog: defineAsyncComponent(() => import('@/views/inventoryDelivery/supplierDeliver/supplierPlan/model/Suplogdialog.vue')),
    LogForm: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/logForm/LogForm.vue')),
    DeliveryReport: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/deliveryReport/DeliveryReport.vue')),
    ClaimDetail: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/responsibility/ClaimDetail.vue')),
    InvoiceStatement: defineAsyncComponent(() => import('@/views/financialManagement/invoiceList/InvoiceStatement.vue')),
    AdddetailDialog: defineAsyncComponent(() => import('@/views/qualityCollaboration/purchase/qualitysystemaudit/projectmaintenance/AdddetailDialog.vue')),
    CabinetShipments: defineAsyncComponent(() => import('@/views/inventoryDelivery/purchaseDeliver/cabinetShipments/CabinetShipments.vue')),
    ComponentSpecification: defineAsyncComponent(() => import('@/views/qualityCollaboration/ComponentSpecification/ComponentSpecification.vue'))
  }
}
